// 全局变量
let BASE_API = "";
if (process.env.NODE_ENV == "development") {
	// BASE_API = "https://mettgpt.nymaite.cn"
	// BASE_API = window.location.origin
	BASE_API = "https://wanghongxiu.com";
	// BASE_API = "http://124.220.23.160:88"
} else if (process.env.NODE_ENV == "production") {
	// BASE_API = window.location.origin  https://jinshaxinxi.cn/ https://wanghongxiu.com/
	BASE_API = "https://wanghongxiu.com";
}

export { BASE_API };
