<template>
<div>

</div>
  <!-- <a-layout-footer class="mb-5">
    <a-row type="flex">
      <a-col :span="24" :md="24">
        <p class="copyright font-regular text-sm">
          友情链接：<a
            href="https://ai.hao.360.com/"
            style="color: #8c8c8c; font-weight: normal"
            target="_black"
          >
            <span class="link">360AI导航</span></a
          >
        </p>
        <br />
        <p class="copyright font-regular text-sm">
          <span class="mr-10"
            >Copyright © 2023 WangHongXiu.com
            金鲨信息科技重庆有限公司（网红秀）版权所有</span
          >
          <br />
          <span
            class="mr-10"
            v-for="(item, index) in config.copyrights"
            :key="'copyright_' + index"
          >
            <img
              :src="item.image ? item.image : ''"
              width="15"
              height="15"
              alt=""
            />
            <a
              style="color: #8c8c8c; font-weight: normal"
              :href="item.url"
              target="_blank"
            >
              <span :class="item.url == '' ? '' : 'link'">{{ item.name }}</span>
            </a>
          </span>
        </p>
      </a-col>
      <a-col :span="24" :md="0" class="footer-control">
				<a-menu mode="horizontal">
					<a-menu-item>Creative Tim</a-menu-item>
					<a-menu-item>About Us</a-menu-item>
					<a-menu-item>Blog</a-menu-item>
					<a-menu-item>License</a-menu-item>
				</a-menu>
			</a-col>
    </a-row>
  </a-layout-footer> -->
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("app", ["config"]),
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
.link:hover {
  color: #333;
}
</style>
